import JustValidate from 'just-validate';

(($) => {
    const formInput = $('.js-subscription-form-input');
    const formInputWrapper = $('.js-subscription-form-input-wrapper');
    const messageSuccess = $('.js-subscription-form-success');
    const messageFailure = $('.js-subscription-form-failure');
    const errorsContainer = $('.js-subscription-form-validation-errors');

    const validation = new JustValidate('#subscriptionForm');

    const showSuccess = () => {
        messageSuccess.fadeIn();
        formInputWrapper.hide();
    };

    const showFailure = () => {
        messageFailure.fadeIn();
        formInputWrapper.hide();
    };

    validation
        .addField(
            '#email',
            [
                {
                    rule: 'required',
                    errorMessage: 'This field is required',
                },
                {
                    rule: 'email',
                    errorMessage: 'Please enter a valid address',
                },
            ],
            {
                errorsContainer: errorsContainer[0],
            },
        )
        .onSuccess((e) => {
            e.preventDefault();
            const target = $(e.currentTarget);
            $.post({
                url: '/mailchimp/send',
                data: target.serialize(),
                success: (r) => {
                    formInput.hide();
                    messageFailure.hide();
                    messageSuccess.hide();

                    const success = r.success == true;
                    const existing = r.success == false && r.msg == "Mailchimp error: Member Exists";

                    if (success || existing) {
                        showSuccess();

                        target.trigger("reset");
                        return;
                    }

                    showFailure();
                }
            });
        });

})(jQuery);
