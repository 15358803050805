export const homepageVideo = ($) => {
    const $video = $('#video')
    const videoPath = $video.attr('src');

    $video.attr('allow', 'autoplay');

    $('.video-play-btn').click(() => {
        $video.attr('src', videoPath);
    });

    $('.close').click(() => {
        $video.attr('src', '');
    });

    const videoModal = document.getElementById('videoModal');

    if (!videoModal) return;

    videoModal.addEventListener('hidden.bs.modal', () => {
        $video.attr('src', '');
    })
}
