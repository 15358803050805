import 'bootstrap';
import "@lottiefiles/lottie-player";
import { carousels } from "./includes/carousels.js";
import { hamburgerMenu } from "./includes/hamburgerMenu.js";
import { homepageVideo } from "./includes/homepageVideo.js";
import { scrollReveals } from "./includes/scrollReveals.js";
import { contactFormValidation } from "./includes/contact-form";
import "./includes/subscription-form";

jQuery.noConflict();

(($) => {
  $(document).ready(() => {
    carousels($);
    hamburgerMenu($);
    homepageVideo($)
    scrollReveals($);
    contactFormValidation($)
  });
})(jQuery);
