import Splide from '@splidejs/splide';

export const carousels = ($) => {

    const carouselBaseConfig = {
        type: 'loop',
        drag: true,
        arrows: true,
        pagination: false,
    };

    const enableCarousels = (className, additionalConfig) => {
        const $carousels = $(`.${className}`);

        if ($carousels.length == 0) return;

        $carousels.each((index, el) => {
            const $el = $(el);
            const uniqueClass = `${className}-${index}`;

            // Add unique class
            $el.addClass(uniqueClass);

            const classArrows = uniqueClass + '-arrows';
            const classArrow = uniqueClass + '-arrow';
            const classArrowPrev = uniqueClass + '-arrow-next';
            const classArrowNext = uniqueClass + '-arrow-prev';

            // Get arrows
            $el.find('.splide__arrows').addClass(classArrows)
                .find('.splide__arrow--prev')
                .addClass(classArrow)
                .addClass(classArrowPrev)
                .siblings('.splide__arrow--next')
                .addClass(classArrow)
                .addClass(classArrowNext);

            const classesConfig = {
                classes: {
                    arrows: 'splide__arrows ' + classArrows,
                    arrow: 'splide__arrow ' + classArrow,
                    prev: 'splide__arrow--prev ' + classArrowPrev,
                    next: 'splide__arrow--next ' + classArrowNext,
                },
            }

            const fullConfig = {
                ...carouselBaseConfig,
                ...additionalConfig,
                ...classesConfig,
            };

            new Splide(`.${uniqueClass}`, fullConfig).mount();
        });
    };

    const enableMediaReleaseCarousels = () => {
        enableCarousels('js-carousel-media', {
            perPage: 3,
            gap: 24,
            breakpoints: {
                1024: {
                    perPage: 2,
                },
                640: {
                    perPage: 1,
                },
            },
        });
    };

    const enablePreviousNewslettersCarousels = () => {
        enableCarousels('js-carousel-newsletters', {
            perPage: 3,
            gap: 24,
            breakpoints: {
                1024: {
                    perPage: 2,
                },
                640: {
                    perPage: 1,
                },
            },
        });
    };

    enableMediaReleaseCarousels();
    enablePreviousNewslettersCarousels();
};
