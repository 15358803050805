import JustValidate from 'just-validate';

export const contactFormValidation = ($) => {
  const $form = $('#form');
  const $errorContainerEmail = $('#error-email');
  const $errorContainerMessage = $('#error-message');

  if ($form.length) {

    const validation = new JustValidate('#form');

    validation
      .addField('#from-email', [
          {
            rule: 'required',
            errorMessage: 'Email is required',
          },
          {
            rule: 'email',
            errorMessage: 'Please enter a valid email address',
          }
        ],
        {
          errorsContainer: $errorContainerEmail[0],
        },
      )
      .addField('#message', [
          {
            rule: 'required',
            errorMessage: 'Message is required',
          },

        ],
        {
          errorsContainer: $errorContainerMessage[0],
        },
      )
      .onSuccess(() => {
        $($form).submit();
      });

  }
};



