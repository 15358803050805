export const hamburgerMenu = ($) => {
  const $collapse = $('.navbar-collapse');
  const $body = $('body');

  $('.navbar-toggler').click(() => {
    $collapse.addClass('show');
    $body.addClass('overflow-hidden');
  });

  $('.btn-close').click(() => {
    $collapse.removeClass('show');
    $body.removeClass('overflow-hidden');
  });
}
